//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import logo from '../assets/logo.svg';
import LookAtMe from '../components/LookAtMe';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function HomePage() {
	const [height, set_height] = useState(window.innerHeight);
	const email = 'contact@attentionlab.com';

	useEffect(() => {
		const handleResize = () => set_height(window.innerHeight);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Box width="100%" height={height} display="flex" alignItems="center" justifyContent="center" overflow="hidden" sx={{ userSelect: 'none' }}>
			<Stack width="100%" maxWidth="768px" height="100%" maxHeight="1080px" alignItems="center" padding="20px" gap="20px">
				<Box width="100%" display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
					<Box component="img" src={logo} width="230px" />

					<Box id="catchphrase" paddingTop="10px">
						<Box display="flex" flexWrap="wrap" justifyContent="flex-start">
							<Typography variant="subtitle1" color="white" sx={{ minWidth: 'max-content', marginRight: '4px' }}>
								{'Full-stack AI & Web3 development'}
							</Typography>
							<Typography variant="subtitle1" color="white" sx={{ minWidth: 'max-content' }}>
								{'specialists for your vision'}
							</Typography>
						</Box>
					</Box>
				</Box>

				<LookAtMe />

				<Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="body2" color="white">
						{'© Attention Lab.'}
					</Typography>

					<Typography variant="body2" component="a" color="white" href={`mailto:${email}`} sx={{ color: 'white', textDecoration: 'none', '&:hover': { color: 'white' } }}>
						{email}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
}
